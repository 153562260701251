<template>
  <div class="text-container" :style="ScrollStyle">
    <transition class="inner-container" name="slide" mode="out-in">
      <p class="text" :key="text.id">{{text.val.phone}} {{ $t('get') }} <span>{{text.val.coupAmt}}</span> {{ $t("succesfully") }}!</p>
    </transition>
  </div>
</template>

<script>
const totalDuration = 3000
export default {
  name: 'ScrollComponent',
  props: {
    ScrollStyle: {
      type: Object,
      default () {
        return {
          color: '#869096',
          background: '#FFFFFF'
        }
      }
    },
    data: Array
  },
  data () {
    return {
      fakeLists: [
        {'phone': '093****791', 'rCount': '5', 'coupAmt': '10000'},
        {'phone': '093****323', 'rCount': '5', 'coupAmt': '4000'},
        {'phone': '093****614', 'rCount': '2', 'coupAmt': '6000'},
        {'phone': '097****654', 'rCount': '2', 'coupAmt': '5000'},
        {'phone': '097****529', 'rCount': '2', 'coupAmt': '6000'},
        {'phone': '094****580', 'rCount': '2', 'coupAmt': '8000'},
        {'phone': '094****983', 'rCount': '2', 'coupAmt': '7000'},
        {'phone': '094****025', 'rCount': '2', 'coupAmt': '9000'},
        {'phone': '094****365', 'rCount': '1', 'coupAmt': '4000'},
        {'phone': '093****781', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '097****058', 'rCount': '1', 'coupAmt': '4000'},
        {'phone': '090****569', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '096****137', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '016*****781', 'rCount': '1', 'coupAmt': '4000'},
        {'phone': '016*****037', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '016*****149', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '012*****422', 'rCount': '1', 'coupAmt': '4000'},
        {'phone': '016*****946', 'rCount': '1', 'coupAmt': '10000'},
        {'phone': '098****201', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '093****639', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '098****541', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '096****527', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '097****769', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '098****867', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '012*****158', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '012*****161', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '016*****966', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '093****265', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '093****670', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '097****186', 'rCount': '1', 'coupAmt': '9000'},
        {'phone': '096****594', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '090****363', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '098****820', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '016*****875', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '093****580', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '016*****056', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '093****475', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '094****568', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '097****198', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '016*****668', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '016*****222', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '093****517', 'rCount': '1', 'coupAmt': '7000'},
        {'phone': '093****203', 'rCount': '1', 'coupAmt': '8000'},
        {'phone': '012*****013', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '098****089', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '012*****299', 'rCount': '1', 'coupAmt': '6000'},
        {'phone': '096****369', 'rCount': '1', 'coupAmt': '5000'},
        {'phone': '016*****403', 'rCount': '1', 'coupAmt': '10000'},
        {'phone': '096****719', 'rCount': '1', 'coupAmt': '4000'},
        {'phone': '090****744', 'rCount': '1', 'coupAmt': '7000'}
      ],
      number: 0
    }
  },
  computed: {
    text () {
      return {
        id: this.number,
        val: this.fakeLists[this.number]
      }
    }
  },
  mounted () {
    this.startMove()
  },
  methods: {
    startMove () {
      let self = this
      if (self && !self._isDestroyed) {
        setTimeout(() => {
          if (self && !self._isDestroyed) {
            if (self.number === 49) {
              self.number = 0
            } else {
              self.number += 1
            }
            self.startMove()
          }
        }, totalDuration)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .text-container {
    padding: 5px 0;
    font-size: 11px;
    padding-left: 55px;
    overflow: hidden;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      background-position: center;
      background-size: 15px 15px;
      width: 15px;
      height: 15px;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      animation: shine .5s ease-in-out infinite;
    }
  }

  .text {
    margin: 0;
    margin-top: 2px;
    /deep/ span {
      color: #000;
    }
  }

  .inner-container {
    animation: myMove 5s linear infinite;
    animation-fill-mode: forwards;
  }

  @keyframes myMove {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(-30px);
    }
    20% {
      transform: translateY(-30px);
    }
    30% {
      transform: translateY(-60px);
    }
    40% {
      transform: translateY(-60px);
    }
    50% {
      transform: translateY(-90px);
    }
    60% {
      transform: translateY(-90px);
    }
    70% {
      transform: translateY(-120px);
    }
    80% {
      transform: translateY(-120px);
    }
    90% {
      transform: translateY(-150px);
    }
    100% {
      transform: translateY(-150px);
    }
  }

  @keyframes shine {
    0% {
      background-image: url("../assets/shine0.png");
    }
    100% {
      background-image: url("../assets/shine1.png");
    }
  }

  .slide-enter-active, .slide-leave-active {
    transition: all 0.5s linear;
  }

  .slide-leave-to {
    transform: translateY(-20px);
  }

  .slide-enter {
    transform: translateY(20px);
  }
</style>
