import { render, staticRenderFns } from "./scrollComponent.vue?vue&type=template&id=dc08a542&scoped=true"
import script from "./scrollComponent.vue?vue&type=script&lang=js"
export * from "./scrollComponent.vue?vue&type=script&lang=js"
import style0 from "./scrollComponent.vue?vue&type=style&index=0&id=dc08a542&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc08a542",
  null
  
)

export default component.exports